import unifiedLoginService from '../services/unified-login.service';
import {
  addClassToElement,
  addEventListener,
  removeAttributeFromElement,
  removeClassFromElement,
} from '../helpers/wireframe.helper';
import { SsoUserData } from '../types/sso-user-data';
import { ExpiredSessionData } from '../types/expired-session-data';

class WireframeService {
  showMyListsMenu(isLoggedIn: boolean) {
    if (isLoggedIn) {
      addClassToElement(document.querySelector('.c24m-mylists-layer'), 'is-loggedIn');
      addClassToElement(document.querySelector('.c24m-contact-layer'), 'is-loggedIn');
      addClassToElement(document.querySelector('.c24m-notification-layer-hover'), 'is-loggedIn');
    }
  }

  hideMyListsMenu(isLoggedIn: boolean) {
    if (isLoggedIn) {
      addClassToElement(document.querySelector('.c24m-contact-layer'), 'is-loggedIn');
    } else {
      removeClassFromElement(document.querySelector('.c24m-contact-layer'), 'is-loggedIn');
    }
  }

  setBackButton() {
    addClassToElement(document.querySelector('#c24m-header'), 'c24m-header-back-option');
  }

  removeBackButton() {
    removeClassFromElement(document.querySelector('#c24m-header'), 'c24m-header-back-option');
  }

  updateAccountLinks() {
    const openLoginLayerHandler = () => {
      unifiedLoginService.openUnifiedLoginLayer();
      return false;
    };

    const logout = () => {
      unifiedLoginService.logout();
      return false;
    };

    removeAttributeFromElement(document.querySelector('#c24m-meinkonto-anmelden'), 'href');
    removeAttributeFromElement(document.querySelector('.c24m-meinkonto-register-link'), 'href');

    addEventListener(document.querySelector('.c24-uliloginlayer-login-opener'), 'click', openLoginLayerHandler);
    addEventListener(
      document.querySelector('#c24m-navi-salutation-guest #c24m-kb-login-navi'), 'click', openLoginLayerHandler);
    addEventListener(
      document.querySelector('#c24m-navi-salutation-expired #c24m-kb-login-navi'),
      'click',
      openLoginLayerHandler
    );
    addEventListener(document.querySelector('#c24m-kb-register-layer'), 'click', openLoginLayerHandler);
    addEventListener(document.querySelector('.c24m-meinkonto-register-link'), 'click', openLoginLayerHandler);
    addEventListener(document.querySelector('.c24-meinkonto-login-link'), 'click', openLoginLayerHandler);
    addEventListener(document.querySelector('.c24-meinkonto-register-link'), 'click', openLoginLayerHandler);
    [
      ...document.querySelectorAll('.c24m-navi-logout'),
      ...document.querySelectorAll('#c24m-kb-logout-layer')
    ].forEach(node => {
      addEventListener(node, 'click', logout);
    });
  }

  _getSsoFormattedNames<TUserData extends { firstName?: string; lastName?: string }>(ssoUser: TUserData) {
    const userNameIsDisplayable = !!ssoUser.firstName && !!ssoUser.lastName;
    const userName = userNameIsDisplayable ? `${ssoUser.firstName} ${ssoUser.lastName}` : '';

    return {
      formattedNameLoginIcon: userName || 'Hallo!',
      formattedNameLogoutIcon: userName ? `Nicht ${userName}?` : '',
      formattedNameMenu: userName ? `Hallo! ${userName}` : 'Hallo!',
      formattedNameLogoutMenu: userName ? `Nicht ${userName}? &nbsp;` : ''
    };
  }

  _setTextToElement(elementSelector: string, text: string) {
    const element = document.querySelector(elementSelector);
    if (element) {
      element.textContent = text;
    }
  }

  _setHtmloElement(elementSelector: string, text: string) {
    const element = document.querySelector(elementSelector);
    if (element) {
      element.innerHTML = text;
    }
  }

  _isLoggedIn(ssoUser: SsoUserData) {
    const {
      formattedNameLoginIcon, formattedNameLogoutIcon, formattedNameMenu, formattedNameLogoutMenu
    } = this._getSsoFormattedNames(ssoUser);

    // login icon items
    this._setTextToElement('.c24m-customer.c24m-customer-user .c24m-customer-layer-salutation-text',
      formattedNameLoginIcon);
    this._setTextToElement('.c24m-customer.c24m-customer-user .c24m-customer-check-q',
      formattedNameLogoutIcon);

    // menu item items
    this._setTextToElement('#c24m-navi-salutation-user #c24m-kb-login-navi', formattedNameMenu);
    this._setHtmloElement('#c24m-kb-logout-navi-user .c24m-customer-check-q', formattedNameLogoutMenu);

    this._setVisibilityForLoggedInState(true);

    this._setVisibilityForExpiredState(false);
    this._setVisibilityForGuestUser(false);
  }

  _isGuestUser() {
    this._setVisibilityForGuestUser(true);

    this._setVisibilityForLoggedInState(false);
    this._setVisibilityForExpiredState(false);
  }

  _isExpiredSession(expiredSessionData: ExpiredSessionData) {
    const {
      formattedNameLoginIcon, formattedNameLogoutIcon, formattedNameMenu, formattedNameLogoutMenu
    } = this._getSsoFormattedNames(expiredSessionData);

    // login icon items
    this._setTextToElement('.c24m-customer.c24m-customer-expired .c24m-customer-layer-salutation-text',
      formattedNameLoginIcon);
    this._setTextToElement('.c24m-customer.c24m-customer-expired .c24m-customer-check-q',
      formattedNameLogoutIcon);

    // menu item items
    this._setTextToElement('#c24m-navi-salutation-expired #c24m-kb-login-navi', formattedNameMenu);
    this._setHtmloElement('#c24m-kb-logout-navi-expired .c24m-customer-check-q', formattedNameLogoutMenu);

    this._setVisibilityForExpiredState(true);

    this._setVisibilityForGuestUser(false);
    this._setVisibilityForLoggedInState(false);
  }

  _setVisibilityForExpiredState(visibility: boolean) {
    const elements = ['#c24m-navi-customer-expired', '.c24m-customer.c24m-customer-expired',
      '#c24m-navi-salutation-expired', '#c24m-kb-logout-navi-expired'];
    this._setElementsVisibility(elements, visibility);
  }

  _setVisibilityForLoggedInState(visibility: boolean) {

    const elements = ['#c24m-navi-customer-user', '.c24m-customer.c24m-customer-user', '#c24m-navi-salutation-user',
      '#c24m-kb-logout-navi-user'];
    this._setElementsVisibility(elements, visibility);
  }

  _setVisibilityForGuestUser(visibility: boolean) {
    const elements = ['#c24m-navi-customer-guest', '.c24m-customer.c24m-customer-guest', '#c24m-navi-salutation-guest'];
    this._setElementsVisibility(elements, visibility);
  }

  _setElementsVisibility(elements: string[], visibility: boolean) {
    if (visibility) {
      elements.forEach((element) => removeClassFromElement(document.querySelector(element), 'is-hidden'));
    } else {
      elements.forEach((element) => addClassToElement(document.querySelector(element), 'is-hidden'));
    }
  }

  updateWireframeAccountController({ ssoUser, expiredSessionData }:{
    ssoUser?: SsoUserData;
    expiredSessionData?: ExpiredSessionData;
  }) {
    if (ssoUser) {
      this._isLoggedIn(ssoUser);
    } else if (expiredSessionData && !expiredSessionData.isUserLoggedOut) {
      this._isExpiredSession(expiredSessionData);
    } else {
      this._isGuestUser();
    }
  }
}

export default new WireframeService();

