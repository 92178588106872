import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import Router from 'next/router';
import url from 'url';

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => Promise<unknown> | unknown;
  disabled?: boolean;
  light?: boolean;
  inverted?: boolean;
  dataTestId?: string;
  hasNavigationSpinner?: boolean;
  prefetch?: string | object | null;
}

function Button({
  children,
  className = '',
  onClick = undefined,
  disabled = false,
  light = false,
  inverted = false,
  dataTestId = '',
  hasNavigationSpinner = false,
  prefetch = null
}: Props) {
  const [isNavigating, setIsNavigating] = useState(false);
  const isMounted = useRef(false);

  const _prefetchIfNeeded = (prefetchLink: string | object | null) => {
    if (prefetchLink) {
      const prefetchLinkStr =
        prefetchLink && typeof prefetchLink === 'object' ? url.format(prefetchLink) : prefetchLink;
      Router.prefetch(prefetchLinkStr);
    }
  };

  useEffect(() => {
    _prefetchIfNeeded(prefetch);
  }, [prefetch]);

  useEffect(() => () => {
    isMounted.current = true;
  }, []);

  const onButtonClick = async () => {
    if (isNavigating) {
      return;
    }
    if (onClick) {
      setIsNavigating(true);
      try {
        await onClick();
      } finally {
        if (!isMounted.current) {
          setIsNavigating(false);
        }
      }
    }
  };

  const renderNavigationIfNeeded = () => {
    if (hasNavigationSpinner && isNavigating) {
      return (
        <span className="Button-navigationSpinner" />
      );
    }
    return null;
  };

  const classNameValue = classnames('Button', className, {
    'Button--light': light,
    'Button--inverted': inverted,
    'is-disabled': disabled
  });

  return (
    <button
      className={classNameValue}
      disabled={disabled}
      data-test-id={dataTestId}
      onClick={onButtonClick}
    >
      {children}
      {renderNavigationIfNeeded()}
    </button>
  );
}

export default Button;

