import React from 'react';
import { observer } from 'mobx-react';

import { isClient } from 'bu.helpers';
import storeFactory from '../factories/store.factory';
import wireframeService from '../services/wireframe.service';
import navigationService from '../services/navigation.service';
import PAGES from '../constants/pages.constant';

import { setNonStickyFooter, setStickyFooter } from '../helpers/wireframe.helper';

type Props = {
  stickyFooter?: boolean;
}

@observer
export default class Wireframe extends React.Component<Props> {

  ssoStore = storeFactory.getSsoStore();

  static defaultProps = {
    stickyFooter: false
  };

  _updateWireframe() {
    if (!isClient()) {
      return;
    }

    const { pathname } = navigationService.getCurrentLink();

    wireframeService.updateWireframeAccountController({ ssoUser: this.ssoStore.ssoUserData,
      expiredSessionData: this.ssoStore.expiredSessionData });
    wireframeService.updateAccountLinks();

    this._updateSinglePages(pathname);
  }

  _updateSinglePages(pathname: string) {
    if (PAGES.TARIFF_DETAILS === pathname || PAGES.INSURANCE_LIST === pathname) {
      wireframeService.setBackButton();
    } else {
      wireframeService.removeBackButton();
    }

    if (PAGES.THANK_YOU === pathname) {
      wireframeService.showMyListsMenu(this.ssoStore.isLoggedIn);
    } else {
      wireframeService.hideMyListsMenu(this.ssoStore.isLoggedIn);
    }

    if (PAGES.THANK_YOU_ERROR === pathname || this.props.stickyFooter) {
      setStickyFooter();
    } else {
      setNonStickyFooter();
    }
  }

  render() {
    this._updateWireframe();
    return null;
  }
}
