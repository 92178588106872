import { DEVICE_OUTPUT } from 'bu.lookups';

/* eslint-disable import/no-unresolved */
// The wireframe files are only available after the build:wireframe has run
import wireframeHeadHtml from '../wireframe/head-prod.html';
import wireframeTestEnvHeadHtml from '../wireframe/head-test.html';
import wireframeBodyHtml from '../wireframe/body-prod.html';
import wireframeTestBodyHtml from '../wireframe/body-test.html';
/* eslint-enable import/no-unresolved */

import configService from '../services/config.service';
import { SsoUserData } from '../types/sso-user-data';

export function getWireframeHead(deviceOutput: string | undefined): string {
  const piwikSiteId = deviceOutput === DEVICE_OUTPUT.MOBILE_APP
    ? configService.getConfig().piwik.appSiteId
    : configService.getConfig().piwik.siteId;

  let head = configService.getConfig().env === 'production' ? wireframeHeadHtml : wireframeTestEnvHeadHtml;

  // Matomo (Piwik) siteId needs to be loaded before the "head" code
  head = `<script id="piwik-site-id-script">window.PIWIK_SITEID=${piwikSiteId}</script>${head}`;

  return head.replace(/<meta\s+name="viewport".*?>/i, '').replace(/<title>.*?<\/title>/, '');
}

export function setStickyFooter() {
  const footer = findManyByClassName('c24m-footer');
  footer?.[0]?.classList.add('is-sticky');
}

export function setNonStickyFooter() {
  const footer = findManyByClassName('c24m-footer');
  footer?.[0]?.classList.remove('is-sticky');
}

export function setMaxHeightForBody() {
  const nextContainer = document.getElementById('__next');
  const [mainElement] = document.getElementsByClassName('Main');
  if (nextContainer && mainElement) {
    nextContainer.classList.add('is-max-height');
    mainElement.classList.add('is-max-height');
  }
}

export function removeMaxHeightForBody() {
  const nextContainer = document.getElementById('__next');
  const [mainElement] = document.getElementsByClassName('Main');
  if (nextContainer && mainElement) {
    nextContainer.classList.add('is-max-height');
    mainElement.classList.remove('is-max-height');
  }
}

export function addClassToElement(element: Element | null, className: string) {
  element?.classList?.add(className);
}

export function removeClassFromElement(element: Element | null, className: string) {
  element?.classList?.remove(className);
}

export function removeAttributeFromElement(element: Element | null, attribute: string) {
  element?.removeAttribute(attribute);
}

export function addEventListener(element: Element | null, event: string, func: () => void) {
  element?.addEventListener(event, func);
}

export function findManyByClassName(className: string): HTMLCollectionOf<Element> {
  return document.getElementsByClassName(className);
}

function prepareWireframeForUserData(bodyHtml: string, userData: SsoUserData): string {
  let replacedBodyHtml = bodyHtml;
  const userNameIsDisplayable = !!userData.firstName && !!userData.lastName;
  const userName = userNameIsDisplayable ? `${userData.firstName} ${userData.lastName}` : 'Mein Konto';

  replacedBodyHtml = replacedBodyHtml.replace(
    'c24m-customer c24m-customer-guest',
    'c24m-customer c24m-customer-guest is-hidden',
  );

  replacedBodyHtml = _replaceAllOccurrences(
    replacedBodyHtml,
    '<!-- EMBED:MEINKONTO_SALUTATION_USERNAME -->',
    `<span class="c24m-customer-name">${userName}</span>`,
  );

  if (!userNameIsDisplayable) {
    replacedBodyHtml = _removeFromTo(
      replacedBodyHtml,
      '<!-- EMBED:MEINKONTO_USERCHECK_START -->',
      '<!-- EMBED:MEINKONTO_USERCHECK_END -->',
    );
  }

  return replacedBodyHtml;
}

export function prepareWireframe({
  html,
  ssoUserData,
  deviceOutput,
}: {
  html: string;
  ssoUserData: SsoUserData | undefined;
  deviceOutput: string | undefined;
}) {
  const bodyHtml = configService.getConfig().env === 'production' ? wireframeBodyHtml : wireframeTestBodyHtml;

  let replacedBodyHtml = bodyHtml.toString().replace('<!-- EMBED:CONTENT_START -->', `<div class="Main">${html}</div>`);

  replacedBodyHtml = replacedBodyHtml.replace(
    'c24m-customer c24m-customer-expired',
    'c24m-customer c24m-customer-expired is-hidden',
  );

  if (ssoUserData) {
    replacedBodyHtml = prepareWireframeForUserData(replacedBodyHtml, ssoUserData);
  } else {
    replacedBodyHtml = replacedBodyHtml.replace(
      'c24m-customer c24m-customer-user',
      'c24m-customer c24m-customer-user is-hidden',
    );
  }

  if (deviceOutput === 'app') {
    replacedBodyHtml = _replacePlaceHoldersForMobileApp(replacedBodyHtml);
  } else {
    replacedBodyHtml =
      // eslint-disable-next-line no-script-url
      _replaceAllOccurrences(replacedBodyHtml, '<!-- EMBED:MEINKONTO_LOGINLINK -->', 'javascript:void(0)');
    replacedBodyHtml =
      // eslint-disable-next-line no-script-url
      _replaceAllOccurrences(replacedBodyHtml, '<!-- EMBED:MEINKONTO_LOGOUTLINK -->', 'javascript:void(0)');
  }

  return replacedBodyHtml;
}

export function setElementVisibility(element: HTMLElement | null, visible: boolean) {
  if (element) {
    element.style.display = visible ? 'block' : 'none';
  }
}

function _replaceAllOccurrences(stringToReplace: string, replaceWhat: string, replaceWith: string): string {
  let replacedString = stringToReplace;
  while (replacedString.indexOf(replaceWhat) > 0) {
    replacedString = replacedString.replace(replaceWhat, replaceWith);
  }
  return replacedString;
}

function _removeFromTo(stringToReplace: string, removeFrom: string, removeTo: string): string {
  const searchExpression = new RegExp(`${removeFrom}[\\s\\S]*${removeTo}`, 'i');
  return stringToReplace.replace(searchExpression, '');
}

function _replacePlaceHoldersForMobileApp(replacedHtml: string): string {
  let replacedHtmlForTabletApp = _removeFromTo(
    replacedHtml,
    '<!-- EMBED:HEADER_START -->',
    '<!-- EMBED:HEADER_END -->',
  );

  replacedHtmlForTabletApp = _removeFromTo(
    replacedHtmlForTabletApp,
    '<!-- EMBED:PRODUCT_DESKTOP_LINK_START -->',
    '<!-- EMBED:PRODUCT_DESKTOP_LINK_END -->',
  );

  return replacedHtmlForTabletApp;
}
